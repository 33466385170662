import { combineReducers } from '@reduxjs/toolkit';
import { reducer as kanbanReducer } from '../slices/kanban';
import { reducer as mailReducer } from '../slices/mail';
import { reducer as authReducer } from '../slices/auth';
import { reducer as notificationReducer } from '../slices/notification';
import { reducer as organizationsReducer } from '../slices/organizations';
import { reducer as usersReducer } from '../slices/users';
import { reducer as rolesReducer } from '../slices/roles';
import { reducer as clientsReducer } from '../slices/clients';
import { reducer as sessionReducer } from '../slices/session';
import { reducer as sessionParticpantReducer } from '../slices/sessionParticipant';

const rootReducer = combineReducers({
  auth: authReducer,
  users: usersReducer,
  kanban: kanbanReducer,
  mail: mailReducer,
  organizations: organizationsReducer,
  roles: rolesReducer,
  notifications: notificationReducer,
  clients: clientsReducer,
  sessionParticipant: sessionParticpantReducer,
  session: sessionReducer
});

export default rootReducer;
